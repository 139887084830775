

const Openjobitems = [
    {
      id: 1,
      title: "Sales Intern-On-site",
      location: "Irving, Texas, United States | Technology | Full Time",
      disc: "Neutrosys Inc is looking for a few recent graduate individuals to join a growing office in the staffing business.This is a great opportunity to ...",
      type: "2021-11-09 - Accepting Applications"
    },
    {
      id: 2,
      title: "Sales Intern",
      location: "Irving, Texas, United States | Technology | Full Time",
      disc: "Neutrosys Inc is looking for a few recent graduate individuals to join a growing office in the staffing business.This is a great opportunity to ...",
      type: "2021-09-01 - Position Closed",
    },
    { 
      id: 3,
      title: "Account Manager",
      location: "Irving, Texas, United States | Technology | Full Time",
      disc: " Neutrosys Inc, a talent and business solutions provider, is seeking to hire an enthusiastic, persuasive, enthusiastic, persuasive, organized and motivated IT Staffing sales professional.",
      type: "2021-09-01 - Position Closed",
    },
    {
      id: 4,
      title: "Linux Administrator - Chicago",
      location: "Chicago, Illinois, United States | Technology | Contract",
      disc: " Responsibilities of the role: Design and Implement serverless software solutions. Play role in understanding business needs in context, from...",
      type: "2021-09-01 - Position Closed",
    },
    // {
    //   id: 5,
    //   title: "Facets Tester - Remote",
    //   location: "MO, Missouri, United States | Technology | Contract",
    //   disc: " We are looking for multiple Healthcare Facets Tester to fill the urgent requirement for one of our clients.",
    // },
    // {
    //   id: 6,
    //   title: "Facets Tester - Remote",
    //   location: "MO, Missouri, United States | Technology | Contract",
    //   disc: " We are looking for multiple Healthcare Facets Tester to fill the urgent requirement for one of our clients.",
    // },
  ]
  
  export default Openjobitems;