const softwaredata=[
    {
        id:1,
        image:"../Servicesimages/web1.png",
        headings:"Web Development",
        discrip:"We help businesses establish their online presence and reach their target audience with functional websites, designed by our expert developers."
    },
    {
        id:2,
        image:"../Servicesimages/software.png",
        headings:"Enterpprise Software Development",
        discrip:"Our services streamline operations for businesses with use of agile methodologies and cutting-edge technologies to create custom software solutions."
    },
    {
        id:3,
        image:"../Servicesimages/ios.png",
        headings:"iOS App Development",
        discrip:"Our iOS app development services deliver engaging mobile experiences with the latest technologies and best practices delivering high-quality iOS apps."
    },
    {
        id:4,
        image:"../Servicesimages/android.png",
        headings:"Android App Development",
        discrip:"We create immersive mobile experiences with our expert developers employing cutting-edge technologies and best practices to deliver high-quality apps."
    }
    
]
export default softwaredata;