// import Letschat from "../letschat/Letschat"
const data=[
    { id:1,
        image:"https://miro.medium.com/max/1194/1*u6wsaofSWkZG4_-7vGto2g.png",
    heading:"Talent Solution",
    disc:"We provide Talent Solutions, including flexible Contract staffing, Contract-to-hire, and Direct hire services, for the best-fit professionals."
    },
    {id:2,
        image:" https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3-eYPl1o6UaZGnv07HoFeVMQ3fmoux-Gj9tRA6TxNP42uA7xhmFqiMZegsQLMC7bkQUk&usqp=CAU",
    heading:"Software Development",
    disc:"We offer Web, Ios, and Android development, and Custom software solutions with cutting-edge technologies and agile methodologies."
    },
    {id:3,
        image:"https://miro.medium.com/max/1400/1*mDuAvJM9_B_IDlfYiQ2P8Q.png",
    heading:"Outsourcing",
    disc:"  We provide Outsourcing solutions for IT services, Software testing, Call center support, and Project management to streamline processes and save costs."
    },
    {id:4,
    image:" https://miro.medium.com/max/700/1*wcrv3-eUOx84Xf5XnVi27w.png",
    heading:"Software Testing",
    disc:" We offer expert Software testing services, including Client server applications, Web and Mobile app testing, with a variety of testing types, to ensure optimal performance. "
    }
    
    ]
    export default data